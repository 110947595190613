import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.css'
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import { ManageLocalStorage } from '../core/LocalStorage';
import { LOGOUT, RENDER_URL } from './Constants';


function LoginHeaderContainer(props) {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    const _history = useNavigate();

    const clearBrowser = (e) => {

        e.preventDefault();
        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();

    }

    return (
        <>
            {showLoader && <div className='sample_loader'>
                <div className='loader_center'>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>}

            <div className="row text-start">
                <div className="col">
                    <Link to='/'>
                        <img style={{ width: "100px", height: "90px", padding: "0" }} src="assets/img/RoyalDegenLogo.png" /></Link>
                </div>
                <div className='col-sm-12' style={{ marginTop: "-50px" }}>
                    <p className='text-center text-white h3 font_desc'>CLAIM YOUR EXCLUSIVE REWARD !</p>
                    <p className='text-center text-white h3 font_desc'>FOR DRiP Haus MEMBERS</p>
                </div>
            </div>


        </>

    )

}

export default LoginHeaderContainer;