import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { history } from "./core/store"
import { httpInterceptor, setBaseUrl ,setApplicationToken } from "./core/HttpService"
import _Routes from './core/Routes'

export default function RouterApp(props) {

  return (

    <_Routes history={history} />

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(<RouterApp />);

// test


reportWebVitals();
