import React, { useState, useEffect, useRef } from 'react';
import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING } from '../common/Constants';
import { register } from './RegisterService'
import './Register.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';
import LoginHeaderContainer from '../common/LoginHeaderContainer'
import FooterContainer from '../common/FooterContainer';
import { Toast } from 'primereact/toast';
import { login } from '../login/LoginService';


function RegisterContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [inviteCode, setInviteCode] = useState("");

    const [username_error, setUserError] = useState("");
    const [password_error, setPasswordError] = useState("");

    const [error_msg, setErrorMsg] = useState()
    const [success_msg, setSuccessMsg] = useState()
    const toast = useRef(null);

    const onForgotClick = () => {
        window.location.href = "https://app.degenroyale.net/reset"
    }
    const clearValidation = () => {
        setUserError("");
        setPasswordError("");
    }

    const onGoToLogin = () => {

        _history('/');

    }

    const onRegisterButtonClick = () => {
        clearValidation();
        if (userName.length <= 0) {
            setUserError('Email cannot be empty');
        }
        else if (!validateEmail(userName)) {
            setUserError('Invalid Email');
        }
        else if (password.length < 8) {
            setPasswordError('Password must be at least 8 chars long');
        }
        else {
            clearValidation();
            registerApi();

        }
    }

    const registerApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const loginData = {
            email: userName,
            password: password
        }

        if (inviteCode != "") {
            loginData["invite_code"] = inviteCode;
        }

        register(loginData).then((response) => {

            debugger;
            if (response.data.success) {

                /*     dispatch({
                        type: STOP_LOADING,
                    });
     */
                loginApi();

                /*  toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
                 setSuccessMsg(response.data.message);
                 _history(RENDER_URL.LOGIN_URL);
  */

            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.error);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }


    const loginApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const loginData = {
            email: userName,
            password: password
        }

        login(loginData).then((response) => {

            if (response.data.success) {
                debugger;
                let userData = {
                    user_token: response.data.token,
                    uuid: response.data.uuid
                }

                ManageLocalStorage.set(USER_TOKEN, userData.user_token)
                ManageLocalStorage.set(USER_DATA, userData);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });

                dispatch({
                    type: STOP_LOADING,
                });


                _history(RENDER_URL.HOME_RENDERURL, { state: { social: response.data.social } });

            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.error);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }

    return (
        <>
            <div className="main_background" style={{ height: '100vh', width: '100%' }}>
                <LoginHeaderContainer></LoginHeaderContainer>
                <div className="container">
                    <Toast ref={toast} />
                    <div className="row justify-content-center" style={{ marginRight: '0px', marginLeft: '0px' }}>
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0px', paddingTop: '0px' }}>
                            <div className="card bg-dark shadow-lg o-hidden border-0 my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="p-5" style={{ padding: '32px', paddingTop: '48px', background: '#f8f9fc', marginTop: '-16px', borderRadius: '6px' }}>
                                                <div className="row">
                                                    <div className="col text-center" style={{ paddingTop: '0px' }}>
                                                        <img style={{ width: '30px', height: '30px', padding: 0 }} src="assets/img/Crown_Black.png" width={100} height={90} />
                                                    </div>
                                                </div>
                                                <div className="row text-center">
                                                    <div className="col" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                                        <h1 style={{ textAlign: 'center', fontFamily: 'ABeeZee, sans-serif', fontSize: '18px', fontWeight: 'bold', color: 'rgb(133, 135, 150)' }}>Create an account</h1>
                                                    </div>
                                                </div>
                                                <div className="text-center"></div>
                                                <div className="user" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                                    <div className="mb-3">
                                                        <input className="form-control form-control-user" value={userName} onChange={(e) => { setUserName(e.target.value) }} type="email" id="InputEmail" aria-describedby="emailHelp" placeholder="Email" name="email" style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                        <span className='error_msg'>{username_error}</span>
                                                    </div>
                                                    <div className="mb-3">
                                                        <input className="form-control form-control-user" type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} id="InputPassword" placeholder="Password" name="password" style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                        <span className='error_msg'>{password_error}</span>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="custom-control custom-checkbox small"></div>
                                                    </div>
                                                    <button className="btn d-block btn-user w-100" type="button" onClick={() => { onRegisterButtonClick() }} style={{ borderRadius: '5px', color: 'var(--bs-white)', fontSize: '16px', background: 'var(--bs-orange)', fontWeight: 'bold', borderWidth: '4px' }}>Create Account</button>
                                                    <span className='error_msg' style={{ padding: '10px', marginTop: '10px' }}>{error_msg}</span>
                                                    <span className='success_msg'>{success_msg}</span>
                                                    <hr />
                                                </div>
                                                <div className="text-center" style={{ paddingTop: '5px', fontFamily: 'ABeeZee, sans-serif', cursor: 'pointer' }}>
                                                    <a onClick={() => { onForgotClick() }} style={{ fontSize: '12px', borderColor: 'var(--bs-gray-500)', color: 'blue', cursor: 'pointer' }}>Forgot Password</a>
                                                </div>
                                                {/* <button onClick={() => { onGoToLogin() }} className="btn btn-primary d-block btn-user w-100" type="submit" style={{ bsBodyFontSize: '11rem', bsBodyFontWeight: 'bold', borderRadius: '5px', background: '#890aff', fontSize: '14px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '6px', borderStyle: 'none' }}>Go to sign in</button> */}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterContainer></FooterContainer>
            </div>
        </>
    );
}

export default RegisterContainer;