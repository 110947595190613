import React from "react"
import { GlobalDispatchContext, GlobalContext } from "./Context"
import { globalReducer, initialGlobal } from "../reducers/CombineReducer"
const ContextStore = (props) => {
    const [globalState, dispatch] = React.useReducer(globalReducer, initialGlobal);
    const states = { ...globalState };
    return (
        <GlobalContext.Provider value={states}>
            <GlobalDispatchContext.Provider value={dispatch}>{props.children}</GlobalDispatchContext.Provider>
        </GlobalContext.Provider>
    );

}
export default ContextStore