import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import LoginContainer from '../login/LoginContainer';
import RegisterContainer from '../register/RegisterContainer'
import { BUILD_VERSION, RENDER_URL, LOGOUT } from '../common/Constants'
import { httpInterceptor, setBaseUrl, setApplicationToken } from "../core/HttpService"
import { ManageLocalStorage } from "./LocalStorage";
import { ManageSessionStorage } from "./SessionStorage";
import { GlobalContext, GlobalDispatchContext } from "../context/Context";
import HomeContainer from "../home/HomeContainer";

const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)

    const clearBrowser = (e) => {
        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
    }

    setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor();


    const loginData = context.login;
    const _buildNo = ManageLocalStorage.get(BUILD_VERSION);
    console.log("buildNo", _buildNo);
    if (_buildNo == null || _buildNo != process.env.REACT_APP_BUILD_VERSION) {
        console.log("ENV", process.env.REACT_APP_BUILD_VERSION)
        clearBrowser(null);
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    } else {
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    }



    return (
        <Routes {...xProps}>
            <Route path={RENDER_URL.REGISTER_URL} exact element={<RegisterContainer />} />
            <Route path={RENDER_URL.LOGIN_URL} exact element={<LoginContainer />} />
            <Route path={RENDER_URL.HOME_RENDERURL} exact element={<HomeContainer />} />

        </Routes>
    );
};
export default RootRouteGuard