import React, { Component } from 'react';



function FooterContainer(props) {

    return (

        <footer className="text-center py-4">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-3 text-center">
                    <div className='col-sm-12 col-md-12 col-lg-12 text-center'>
                        <img src='../assets/img/DRIPLOGO.png' style={{ height: '30px' }} />
                        <img src='../assets/img/DGR.png' style={{ height: '30px' }} />
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default FooterContainer;