import { useEffect, useState } from "react";
import DegenLogoContainer from "../common/DegenLogo";
import { useLocation } from "react-router-dom";

const HomeContainer = () => {

    const [showCongrats, setShowCongrats] = useState(false);
    const _location = useLocation();
    const [social, setSocialData] = useState(_location.state.social);





    useEffect(() => {

        console.log("social", social);

        if (social.credited == true) {
            setShowCongrats(true);
            window.setTimeout(() => {
                setShowCongrats(false)
            }, 3000)
        }

    }, [social]);


    const onJoinButtonClick = () => {
        window.location.href = "https://discord.gg/degen-royale"
    }

    return <>

        <div className="home_container" style={{ height: '100vh', width: '100%' }}>
            <DegenLogoContainer></DegenLogoContainer>
            <div className="container inner-container">
                <div className="row justify-content-center" style={{ marginRight: '0px', marginLeft: '0px' }}>
                    <div className="col-lg-6 col-md-12 col-sm-12" style={{ padding: '0px', paddingTop: '0px' }}>
                        <div className="row">
                            {social?.credited && <div className="col-lg-12 col-md-12 col-sm-12">
                                <p className="text-center text-white font_desc">
                                    CONGRATULATIONS
                                </p>
                                <p className="text-center text-white  font_desc">
                                    You have unlocked your game coins
                                </p>
                            </div>
                            }
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                <img src="../assets/img/Chest_Single.png" className="img img-resposive" style={{ width: '70%' }} />
                            </div>
                            <div className="col-sm-12">
                                <p className="text-center font_desc" style={social?.credited ? { color: '#f3e00d' } : { color: '#c32a2a' }}>{social?.coins ? `${social.coins} GAME COINS` : 'You have already claimed this reward!'} </p>
                                <p className="text-center text-white">Use your game coins in Degen Royale game on Steam now. Or accumulate more treasures as the stories unfold every week to an epic new Adventure.</p>
                                <p className="text-center text-white" style={{ fontSize: '10px' }}> <b>game coins are not cryptocurrency</b></p>
                            </div>
                            <div className="col-sm-12 text-center">
                                <button className="btn btn-color-joinus " onClick={() => { onJoinButtonClick() }}>
                                    JOIN OUR COMMUNITY
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            {showCongrats && <div className="congrats_container" style={{ height: '100vh', width: '100%', position: 'absolute', top: '0' }}></div>}
        </div >
    </>
}


export default HomeContainer;